import React from 'react'
import PdfViewer from '@bit/nspiracbd.nspira-modules.pdf-viewer';

const PdfViewerComp = ({ match, location }) => {
  return (
    <div  >
      <PdfViewer
        match={match}
        location={location}
        hasDownload
      />
    </div>
  )
}

export default PdfViewerComp
